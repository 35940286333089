.table
  font-family: Inter, sans-serif !important
  border-collapse: collapse
  background: white
  width: 100%
  border-radius: 24px
  tbody
    color: #000
  thead
    background: #000
    color: #fff
  th
    border-bottom: 1px solid #BDBDBD
    border-right: 1px solid #BDBDBD
    padding: 20px
    font-size: 16px
    font-weight: 700
    &._large
      font-size: 16px
    &:last-child
      border-right: none
      padding-right: 16px
  tr
    border-bottom: 1px solid #BDBDBD
    &:first-child
      th
        &:first-child
          padding-left: 16px
    //&:last-child
    //  border: 0
  tfoot
    tr
      border: 0
  td
    padding: 20px
    //border-bottom: 1px solid #e0e0e0
    vertical-align: top
    &:first-child
      padding-left: 20px
      font-weight: 800
    &:last-child
      padding-right: 20px
    &._right
      text-align: right
  //tfoot
  //  td
  //    +font(2.2rem, 600, var(--color1))

//.table-wrapper
//  border-radius: 10px
//  overflow: hidden
//  &__mobile-col
//    display: none

//.table-scroll
//  overflow: hidden
//  width: 100%
//  overflow-x: auto

.table-account
  //box-shadow: 0px 11px 20px rgba(171, 171, 171, 0.24)
  td
    white-space: nowrap
    font-size: 16px
    text-align: center
    &:first-child,
    &:nth-child(2)
      text-align: left
  tr
    &:last-child
      th
        &:first-child
          white-space: nowrap
    &:first-child
      th
        &:first-child,
        &:nth-child(2)
          text-align: left


.table-scroll
  border-radius: 24px
  overflow: hidden
  border: 1px solid #fff

//.table-offer
//  td
//    padding: 24px
//    border-bottom: 1px solid var(--color14)
//    width: calc(100% / 4)
//    line-height: 1.25
//    &:first-child
//      padding-left: 0
//    &:last-child
//      padding-right: 0
//  tr
//    &._no-border
//      td
//        padding-top: 16px
//        padding-bottom: 16px
//        border-bottom: none
//  &__group
//    //+flex(row nowrap, flex-start, flex-start)
//  &__label
//    margin-right: 16px
//    padding-top: 10px

@import "media"
