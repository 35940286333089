/*.app .button._disabled {*/
/*  border: 1px solid;*/
/*  background: var(--color10);*/
/*  cursor: default;*/
/*}*/

/*.app .button._disabled:hover {*/
/*  -webkit-filter: none;*/
/*  filter: none;*/
/*}*/

.highcharts-credits {
  display: none;
}

.highcharts-scrollbar {
  display: none;
}

/*.app .table td.error {*/
/*  text-align: right;*/
/*  vertical-align: middle;*/
/*  color: #ee1b1b;*/
/*  font-weight: bold;*/
/*  font-size: 16px;*/
/*}*/

.mr-8 {
  margin-right: 8px;
}
/*.app .table td.redirect {*/
/*  text-align: left;*/
/*  vertical-align: middle;*/
/*  font-weight: bold;*/
/*  font-size: 16px;*/
/*}*/
/*.app .table td.redirect .redirect {*/
/*  color: #008cb9;*/
/*}*/

.w-dropdown {
  z-index: 1;
}
/*.app .table td.redirect .redirect_error {*/
/*  color: #ee1b1b;*/
/*}*/

.w-form-fail {
  border-radius: 8px;
}
/*.app .table td.onchain {*/
/*  text-align: right;*/
/*  vertical-align: middle;*/
/*  padding: 15px;*/
/*}*/

.mr-8 {
  margin-right: 8px;
}

.div-block-62 {
  min-height: auto;
}
.div-block-62-table {
  padding: 0;
}

.div-block-74-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  /*width: 100%;*/
}

.div-block-74-buttons .ouline-nav {
  background-color: #fff;
}
.div-block-74-buttons .ouline-nav:hover {
  background-color: #000;
}
.div-block-74-buttons .ouline-nav.disabled {
  background: #acacac;
  border: 0;
}

.w-dropdown .close {
  display: none;
}

.select-field {
  text-align: right;
}
@media screen and (max-width: 479px) {
  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980852-6390b227 {
    grid-row-start: 3;
  }
}

.h4-bold---24-32.white.policy {
  margin-top: 32px;
  margin-bottom: 16px;
}

.p1-regular-16-24.white.policy {
  margin-bottom: 16px;
}

.div-block-53 > h3:first-child {
  margin-top: 0 !important;
}

a.link-color {
  color: #fcd510;
}

a.link-color:hover {
  text-decoration: none;
}

b.p1-regular-16-24.white.policy {
  font-weight: 800;
}

#content {
  min-height: auto;
  margin-top: 56px;
}

.w-layout-grid .div-block-50 {
  background-color: #000;
}

.w-layout-grid .tradng {
  border: 1px solid #fff;
}

.w-layout-grid .div-block-50 .h5---20-32.dash {
  color: #fff;
  font-weight: 700;
}

.div-block-70-graph {
  padding-left: 0;
  padding-bottom: 0;
}

.token-contract-wallets {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.token-contract {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.token-wallets {
  display: flex;
  gap: 8px;
}
.token-wallets-link {
  color: #000;
  font-weight: bold;
}
.token-wallets-link:hover {
  text-decoration: none;
}

.addToMetaMask {
  margin-left: 8px;
  border-radius: 4px;
  color: #0d0d0d;
}
.addToMetaMask:hover {
  background: #0d0d0d;
  color: #fff;
}

.h4-regular---24-32.hero {
  width: 436px;
  margin-bottom: 32px;
  color: #717171;
  text-align: center;
}

.product-h6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  margin-top: 16px;
}

.product-empty {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}
.product-empty img {
  max-width: 240px;
}
.product-empty .div-block-58 {
  position: relative;
  display: inline-block;
  left: 12px;
  top: 0;
  color: #000;
}
.w-form-fail {
  line-height: 20px;
}
@media screen and (max-width: 767px) {
  .div-block-74-buttons {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .div-block-74-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

.agents-head {
  display: flex;
  /*justify-content: space-between;*/
  max-width: 880px;
  width: 100%;
  flex-wrap: nowrap;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}
.agents-head .h2---44-52 {
  margin-bottom: 0!important;
}
.agents-head button {
  /*flex-shrink: 0;*/
}
@media screen and (max-width: 991px) {
  .agents-head {
    margin-top: 32px;
  }
  .agents-head .h2---44-52 {
    margin-bottom: 0!important;
    margin-top: 0!important;
  }
}
@media screen and (max-width: 767px) {
  .agents-head {
    flex-direction: column;
    align-items: flex-start;
  }
  /*.agents-head .h2---44-52.head.white.policy {*/
  /*  margin-bottom: 24px;*/
  /*}*/
}

.agents .div-block-17 {
  background-color: #7b7b7b;
}
.agents .div-block-17:last-child {
  display: none;
}
.agents .input-read-group {
  display: flex;
  gap: 32px;
  margin-bottom: 24px;
}
.agents .input-read-group .input-title {
  margin-bottom: 4px;
}
@media screen and (max-width: 767px) {
  .agents .input-read-group {
    flex-direction: column;
    gap: 24px;
  }
}
.agents-form {
  margin-bottom: 48px;
}
.agents-form .agents-form-rows {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.agents-form .agents-form-row {
  display: flex;
  gap: 24px;
}
.agents-form .agents-form-row.actions {
  align-items: flex-end;
  gap: 16px
}
.agents-form .agents-form-row.actions .div-block-19 {
  margin-right: 0;
}
.agents-form .div-block-74 {
  width: 50%;
}
@media screen and (max-width: 413px) {
  .agents-form .div-block-74 {
    width: 100%;
  }
  .agents-form .agents-form-rows {
    gap: 8px;
  }
  .agents-form .agents-form-row {
    flex-direction: column;
    gap: 8px;
  }
}
.agents-form .agents-form-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
/*.agents-form .agents-form-head h3 {*/
/*  margin-top: 0;*/
/*}*/
.agents-form .close {
  transform: rotate(45deg);
}

@media screen and (max-width: 812px) {
  .div-block-11 {
    flex-direction: column;
    gap: 12px;
  }
}
@media screen and (max-width: 767px) {
  .div-block-11 {
    flex-direction: column;
    gap: 0;
  }
  .login .agents-form-row {
    flex-direction: column;
  }
  .login .agents-form-row .div-block-74 {
    width: 100%;
  }
}
@media screen and (max-width: 321px) {
  .counter {
    font-size: 22px;
  }
  .stats._1 {
    width: 100%;
  }
}

.inputs-customers .input-read-group {
  justify-content: space-between;
}
.inputs-customers .date {
  text-align: right;
}
@media screen and (max-width: 767px) {
  .inputs-customers .date {
    text-align: left;
  }
}
.div-block-52.tradng {
  min-height: auto;
}
input.w-input:disabled {
  background: #aaa9a9;
}
.agents-form.login {
  /*max-width: 320px;*/
}
.inputs-customers .div-block-17 {
  margin: 24px 0;
  display: block!important;
}
.inputs-customer:last-child .div-block-17 {
  display: none!important;
  margin-bottom: 0;
}

.customers-tabs {
  display: flex;
  gap: 24px
}
.transaction {
  border-bottom: 1px solid #7b7b7b;
  padding-bottom: 24px;
}
.transaction:last-child {
  border-bottom: 0;
  padding: 0;
}
.transaction-input-group {
  display: flex;
  gap: 24px;
}
@media screen and (max-width: 415px) {
  .transaction-input-group {
    flex-direction: column;
  }
}

.admin-flex-blocks {
  max-width: 880px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}
.admin-flex-blocks .flex-block .flex-block-header {
  display: flex;
  justify-content: space-between;
}
.flex-title {
  color: #fff;
}
.flex-title.right {
  text-align: right;
}
@media screen and (max-width: 413px) {
  .flex-title.right {
    text-align: left;
  }
}
.flex-block.grow-100 {
  grid-column: span 2;
}
.flex-box-content.stats {
  justify-content: space-between;
  text-align: center;
}
.flex-box-content.stats .h2---44-52 {
  font-size: 36px;
  line-height: 44px;
}

@media screen and (max-width: 767px) {
  .admin-flex-blocks {
    grid-template-columns: repeat(1, 1fr);
  }
  .flex-block.grow-100 {
    grid-column: 1;
  }
  .flex-box-content.stats {
    flex-direction: column;
    /*text-align: left;*/
    gap: 24px;
  }
}
.admin-navigation-bar {
  margin-bottom: 16px;
  display: flex;
  max-width: 880px;
  width: 100%;
}
.customers {
  margin-bottom: 16px;
}
.customers .customers-tabs h3 {
  margin-top: 0!important;
}
.customers .input-read-group:last-child {
  margin-bottom: 0!important;
}
.admin-filter-bar {
  /*margin-top: -16px;*/
  margin-bottom: 24px;
  display: grid;
  max-width: 880px;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
}
.admin-filter-bar.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.admin-filter-bar .div-block-74 {
  margin-bottom: 0;
}
.admin-filter-bar .div-block-19 {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .admin-filter-bar {
    grid-template-columns: repeat(2, 1fr);
  }
  .admin-filter-bar.col-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 413px) {
  .admin-filter-bar {
    grid-template-columns: repeat(1, 1fr);
  }
}
.flex-block-buttons {
  display: flex;
  gap: 8px;
}
@media screen and (max-width: 767px) {
  .flex-block-buttons {
    flex-direction: row;
    width: 100%;
  }
}
@media screen and (max-width: 412px) {
  .flex-block-buttons {
    flex-direction: column;
    width: 100%;
  }
}
.agents-title {
  width: auto!important;
}
.w-flex-button {
  color: #ffd900;
  background: #0d0d0d;
  border: 1px solid #ffd900;
}
.w-flex-button:hover {
  border: 1px solid #0d0d0d;
  background: #0d0d0d;
}
.admin .text-field-2, .admin .div-block-19, .admin .submit-button {
  height: 44px;
}
.admin .div-block-19 {
  padding-right: 12px;
}
.admin .submit-button {
  padding: 8px 20px;
}
.admin .div-block-74 ._2 {
  margin-bottom: 8px;
}