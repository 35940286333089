.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.body {
  padding: 24px;
  background-color: #000;
}

.typography {
  display: -ms-grid;
  display: grid;
  margin-bottom: 124px;
  padding: 56px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 24px;
  background-color: #bcbdc1;
}

.buttons {
  display: -ms-grid;
  display: grid;
  margin-bottom: 124px;
  padding: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-radius: 24px;
  background-color: #bcbdc2;
}

.h1---52-64 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 52px;
  line-height: 64px;
  font-weight: 800;
}

.h1---52-64.yellow {
  margin-bottom: 16px;
  color: #ffd900;
}

.h1---52-64.white {
  color: #fff;
}

.h2---44-52 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #0d0d0d;
  font-size: 44px;
  line-height: 52px;
  font-weight: 800;
}

.h2---44-52.head {
  width: 100%;
  max-width: 1328px;
  margin-bottom: 56px;
  color: #0d0d0d;
}

.h2---44-52.head.white {
  color: #fff;
}

.h2---44-52.head.white._2 {
  max-width: 556px;
}

.h2---44-52.head.white._3 {
  width: 314px;
  margin-right: 106px;
}

.h2---44-52.head.white.dashh {
  margin-bottom: 0px;
}

.h2---44-52.head.white.policy {
  max-width: 880px;
  margin-bottom: 40px;
}

.h2---44-52.head._2 {
  margin-bottom: 16px;
}

.h2---44-52.annual {
  color: #00d208;
}

.h2---44-52.annual.prdddy.dashy {
  text-align: center;
}

.h2---44-52.dolla {
  margin-right: 4px;
}

.h2---44-52.join {
  line-height: 48px;
}

.h2---44-52.white {
  color: #fff;
}

.h2---44-52.yellow {
  color: #ffd900;
}

.h2---44-52.prody.dashy {
  color: #000;
}

.h2---44-52.whte {
  margin-bottom: 16px;
  color: #fff;
}

.h2---44-52.dash {
  color: #fff;
  text-align: center;
}

.heading {
  font-family: Inter, sans-serif;
  font-size: 32px;
  line-height: 40px;
}

.h3---32-40 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 800;
  letter-spacing: 1px;
}

.h3---32-40.form {
  margin-bottom: 32px;
}

.h3---32-40.black {
  color: #000;
  letter-spacing: 0.5px;
}

.h3---32-40.empty {
  width: 590px;
  margin-bottom: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.h4-bold---24-32 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.h4-bold---24-32.prod {
  margin-bottom: 10px;
}

.h4-bold---24-32.prod._2 {
  width: 285px;
  min-width: 285px;
  margin-right: 24px;
}

.h4-bold---24-32.disabled {
  color: #717171;
}

.h4-bold---24-32.white {
  margin-bottom: 12px;
  color: #fff;
}

.h4-bold---24-32.white._2 {
  margin-bottom: 0px;
}

.h4-bold---24-32.white.policy {
  margin-bottom: 32px;
}

.h4-bold---24-32.mob {
  color: #000;
}

.h4-regular---24-32 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

.h4-regular---24-32.yellow {
  margin-bottom: 24px;
  color: #ffd900;
}

.h5---20-32 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}

.h5---20-32.team {
  max-width: 650px;
}

.h5---20-32.team._2 {
  max-width: 50%;
  color: #717171;
}

.h5---20-32.white {
  color: #fff;
}

.h5---20-32.acc {
  width: 656px;
  margin-bottom: 24px;
  color: #e5e5ea;
}

.h5---20-32.acc.empty {
  margin-bottom: 0px;
}

.h5---20-32.dash {
  color: #202020;
}

.p1-bold-16-24 {
  margin-top: 0px;
  margin-right: 16px;
  margin-bottom: 0px;
  background-color: transparent;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.p1-bold-16-24.text {
  margin-right: 0px;
}

.p1-bold-16-24.yellow {
  color: #ffd900;
}

.p1-bold-16-24.tabs {
  color: #fff;
}

.p1-bold-16-24.tabb {
  margin-right: 24px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 2px solid #0d0d0d;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

.p1-bold-16-24.tabb:hover {
  color: #717171;
}

.p1-bold-16-24.tabb.w--current {
  margin-right: 24px;
  padding-right: 0px;
  padding-left: 0px;
  border-bottom: 2px solid #fff;
  background-color: transparent;
}

.p1-regular-16-24 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.p1-regular-16-24.form {
  color: #717171;
}

.p1-regular-16-24.hero {
  color: #717171;
}

.p1-regular-16-24.white {
  color: #fff;
}

.p1-regular-16-24.white._2 {
  margin-bottom: 24px;
}

.p1-regular-16-24.dasssh {
  margin-bottom: 8px;
  color: #fff;
  text-align: right;
}

.p1-regular-16-24.auth {
  margin-top: 16px;
  color: #717171;
}

.p2-regular-14-24 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #717171;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.p2-regular-14-24.foot-small {
  color: #717171;
}

.p2-regular-14-24.product {
  margin-bottom: 8px;
}

.p2-regular-14-24.product.dashy {
  margin-bottom: 0px;
}

.p2-regular-14-24.prod {
  margin-bottom: 24px;
}

.p2-regular-14-24.prod._2 {
  margin-bottom: 12px;
}

.p2-bold-14-24 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px 32px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: #ffd900;
  color: #000;
  text-decoration: none;
}

.button.main {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button.main:hover {
  background-color: #fff;
}

.button.main.foot {
  margin-right: 0px;
}

.button.main.join {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-width: 2px;
  border-color: #ffd900;
}

.button.main.join:hover {
  border: 2px solid #000;
}

.button.main.security {
  margin-top: 32px;
}

.button.text {
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button.text:hover {
  background-color: #fff;
}

.button.outlined {
  padding: 15px 31px;
  border-style: solid;
  border-width: 2px;
  border-color: #000;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button.outlined:hover {
  background-color: #000;
  color: #fff;
}

.button.outlined.foot {
  margin-right: 20px;
  border-color: #fff;
  color: #fff;
}

.button.outlined.foot:hover {
  background-color: #fff;
  color: #000;
}

.button.outlined.hero {
  margin-right: 24px;
  border-color: #ffd900;
  color: #ffd900;
}

.button.outlined.hero:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}

.button.form {
  height: 56px;
  padding-right: 44px;
  padding-left: 44px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.button.form:hover {
  background-color: #fff;
  color: #000;
}

.button.disabled {
  background-color: #aaa9a9;
  color: #858585;
  cursor: default;
}

.buton-text {
  margin-right: 16px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.buton-text.text {
  margin-right: 0px;
}

.buton-text.acc {
  margin-right: 0px;
}

.navbars {
  margin-bottom: 124px;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 68px;
  padding: 12px 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffd900;
}

.brand {
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.main-nav {
  margin-left: 12px;
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 8px;
  background-color: #000;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Inter, sans-serif;
  color: #ffd900;
  line-height: 24px;
  font-weight: 600;
}

.main-nav:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000;
}

.ouline-nav {
  padding: 10px 20px;
  border: 2px solid #000;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Inter, sans-serif;
  color: #000;
  line-height: 24px;
  font-weight: 600;
}

.ouline-nav:hover {
  border-color: transparent;
  background-color: #fff;
  color: #000;
}

.image {
  margin-right: 24px;
}

.nav-link {
  padding: 10px 12px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: Inter, sans-serif;
  color: #000;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
}

.nav-link:hover {
  opacity: 0.6;
}

.nav-link.w--current {
  color: #000;
}

.footers {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 80px 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer.open {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #0d0d0d;
}

.footer.collapsed {
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #0d0d0d;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo-foot {
  margin-bottom: 24px;
}

.logo-foot.small {
  height: 32px;
  margin-right: 32px;
  margin-bottom: 0px;
}

.foot-link {
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: Inter, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
}

.foot-link:hover {
  opacity: 0.5;
}

.foot-link.smal {
  padding-right: 12px;
  padding-left: 12px;
}

.foot-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.foot-menu.small {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 86px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-form {
  padding: 56px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 24px;
  background-color: #202020;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-field {
  height: 56px;
  margin-right: 24px;
  margin-bottom: 0px;
  padding: 16px 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  color: #717171;
  font-size: 16px;
  line-height: 24px;
}

.text-field:hover {
  background-color: hsla(0, 0%, 100%, 0.85);
  color: #202020;
}

.text-block {
  font-family: Inter, sans-serif;
}

.form-block {
  margin-bottom: 32px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.body-2 {
  background-color: #0d0d0d;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 96px 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section.hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 634px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url("../images/Слой-3.svg"), url("../images/Слой-6.svg"),
    url("../images/Слой-2.svg"), url("../images/Слой-5.svg"),
    url("../images/Слой-8.svg"), url("../images/Слой-4.svg");
  background-position: 10% 90%, 90% 35%, 90% 100%, 3% 25%, 35% 25%, 70% 15%;
  background-size: auto, auto, auto, auto, auto, auto;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
}

.section.fund-products {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e5e5ea;
}

.section.fund-products.mobile {
  display: none;
}

.section.grey {
  overflow: scroll;
  min-height: auto;
  background-color: #e5e5ea;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
}

.section.grey._2 {
  height: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.section._2 {
  height: 90vh;
}

.section._2.policy {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section._2.policy.auth {
  height: auto;
  min-height: 90vh;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-2 {
  margin-bottom: 32px;
}

.div-block-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-11 {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: -70px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 48px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 32px;
  background-color: #fff;
}

.stats {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.stats._1 {
  margin-right: 56px;
}

.image-3 {
  margin-right: 24px;
}

.div-block-12 {
  overflow: hidden;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 16px;
  padding: 32px 56px;
  border-radius: 40px;
  background-color: #fff;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 76px;
  margin-bottom: 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-13.new {
  margin-right: 24px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-13.new.dash {
  width: 100%;
}

.div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.image-4 {
  margin-left: 32px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.div-block-16 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  -ms-grid-columns: 285px 1fr 1fr;
  grid-template-columns: 285px 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.list-item {
  margin-bottom: 12px;
}

.list {
  padding-left: 24px;
}

.div-block-17 {
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #202020;
}

.div-block-18 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.select-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 60px;
  margin-right: 14px;
  margin-bottom: 0px;
  padding: 14px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #000;
  background-color: transparent;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 60px;
  margin-right: 16px;
  border-radius: 8px;
  background-color: #e9e9e9;
}

.text-field-2 {
  height: 60px;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px none #000;
  background-color: transparent;
  color: #000;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.submit-button {
  height: 60px;
  padding: 16px 44px;
  border-radius: 8px;
  background-color: #ffd900;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Inter, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.submit-button:hover {
  background-color: #000;
  color: #fff;
}

.submit-button.disabled {
  width: auto;
  background-color: #acacac;
  color: #707070;
}

.div-block-21 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 48px;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.howit {
  padding: 32px;
  border-radius: 24px;
  background-color: #202020;
}

.div-block-23 {
  margin-bottom: 24px;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.image-5 {
  margin-left: 16px;
}

.counter {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #000;
  font-size: 44px;
  line-height: 52px;
  font-weight: 800;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-26 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1328px;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 264px;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
}

.image-6 {
  width: 120px;
  height: 120px;
  margin-bottom: 24px;
}

.div-block-28 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1328px;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  background-color: #202020;
}

.image-7 {
  height: 96px;
  margin-bottom: 32px;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 24px;
  background-color: #fff;
}

.div-block-31 {
  position: static;
  left: 56px;
  top: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-31._2 {
  width: 50%;
  margin-bottom: 0px;
}

.paragraph {
  max-width: 650px;
}

.div-block-32 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1328px;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 24px;
  background-color: #fff;
}

.div-block-34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-block-35 {
  min-width: 380px;
  margin-right: 80px;
}

.div-block-36 {
  margin-bottom: 56px;
}

.div-block-37 {
  width: 100%;
  height: 1px;
  background-color: #717171;
}

.div-block-38 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 56px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-39 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 1328px;
  grid-auto-columns: 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-40 {
  padding: 32px;
  border-radius: 24px;
  background-color: #fff;
}

.image-8 {
  height: 60px;
  margin-bottom: 24px;
}

.div-block-41 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url("../images/Frame-7289.svg");
  background-position: -1% 110%;
  background-size: auto 224px;
  background-repeat: no-repeat;
}

.div-block-42 {
  display: -ms-grid;
  display: grid;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-43 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-44 {
  width: 100%;
  max-width: 1328px;
  -webkit-perspective: 500px;
  perspective: 500px;
  -webkit-transform: perspective(500px);
  transform: perspective(500px);
}

.marquee-cover-horizontal {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 10;
  background-image: none;
}

.marquee-horizontal-large {
  position: relative;
  z-index: 200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 180px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.track-horizontal-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal.flex-left {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-horizontal.flip-svp {
  width: 100vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.icon-container-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 16px;
  box-shadow: 0 7px 15px 3px rgba(175, 177, 179, 0.3);
}

.icon {
  width: auto;
}

.spacer-30 {
  width: 100%;
  height: 30px;
}

.spacer-30._15-xsvp {
  width: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.header-quaternary {
  max-width: 100%;
  font-size: 14px;
  letter-spacing: 1px;
}

.header-quaternary.grey-text.uppercase {
  padding: 2px 4px;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  padding: 10px 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 8px;
  background-color: #8f00ff;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.container {
  position: relative;
  z-index: 10;
  width: 90%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 60px;
  padding-left: 60px;
}

.container.flex-vertical {
  max-width: none;
  padding-right: 0px;
  padding-left: 0px;
}

.paragraph-large {
  font-size: 20px;
}

.flex-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.spacer-60 {
  width: 100%;
  height: 60px;
}

.spacer-60._60-width {
  width: 60px;
}

.header-secondary {
  max-width: 100%;
  font-size: 40px;
  line-height: 120%;
}

.track-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.spacer-15 {
  width: 100%;
  height: 15px;
}

.col {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col.small {
  width: 41%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.marquee-horizontal {
  position: relative;
  z-index: 200;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #00f;
}

.marquee-text {
  margin-right: 8vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  text-transform: uppercase;
}

.div-block-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.body-3 {
  background-color: #0d0d0d;
}

.div-block-47 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 878px;
  padding: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 24px;
  background-color: #202020;
}

.div-block-47.empty {
  background-image: url("../images/Frame-7331.svg");
  background-position: 90% 100%;
  background-size: auto;
  background-repeat: no-repeat;
}

.body-4 {
  background-color: #0d0d0d;
}

.div-block-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tabs {
  width: 100%;
  max-width: 1328px;
  margin-top: -32px;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
}

.grid-2 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  -ms-grid-columns: 356px 1fr 1fr;
  grid-template-columns: 356px 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.div-block-49 {
  border-radius: 16px;
  background-color: #202020;
}

.div-block-50 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 64px;
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #fff;
}

.div-block-51 {
  padding: 32px;
}

.div-block-52 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 64px;
  padding: 16px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 16px;
  background-color: #202020;
}

.div-block-52.tradng {
  overflow: hidden;
  height: auto;
  min-height: 320px;
  padding: 0px;
  background-color: #fff;
}

.html-embed {
  width: 100%;
  height: 100%;
  margin-top: 24px;
}

.text-block-2 {
  text-align: right;
}

.heading-2 {
  text-align: right;
}

.body-5 {
  background-color: #0d0d0d;
}

.div-block-53 {
  width: 100%;
  max-width: 880px;
  padding: 32px;
  border-radius: 24px;
  background-color: #202020;
}

.body-6 {
  background-color: #0d0d0d;
}

.faq-p {
  overflow: hidden;
  width: 100%;
  max-width: 1328px;
  margin-bottom: 10px;
  border-radius: 40px;
  background-color: #fff;
}

.faq-p.mobile {
  display: none;
}

.faq {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 140px;
  max-width: 1328px;
  padding: 0px 50px 0px 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 32px 32px 0px 0px;
  background-color: #f3f3f3;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  white-space: break-spaces;
}

.faq:hover {
  background-color: #fff;
}

.faq.w--open {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.faq-img {
  position: relative;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-icon {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 24px;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq-body {
  position: relative;
  display: block;
  width: 100%;
  margin-top: -1px;
  background-color: transparent;
}

.faq-body.w--open {
  background-color: transparent;
}

.faq-body-cont {
  padding: 30px 56px;
  border-top: 1px none #000;
  background-color: #fff;
  font-size: 16px;
  line-height: 32px;
  font-weight: 300;
}

.faq-answer {
  margin-bottom: 24px;
}

.faq-answer-img {
  width: 100%;
}

.div-block-54 {
  position: relative;
  z-index: 10;
  display: none;
  width: 48px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 40px;
  background-color: #0d0d0d;
}

.div-block-55 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  max-width: 1328px;
  padding-left: 0px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.image-11 {
  margin-right: 24px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.melted {
  width: 60px;
  height: 60px;
  padding: 0px;
  border-radius: 2px;
  background-color: #000;
}

.melted.w--open {
  background-color: #000;
}

.top-bubble {
  position: absolute;
  left: 0px;
  top: 18px;
  right: 0px;
  display: block;
  width: 32px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50px;
  background-color: #fff;
}

.middle-bubble {
  position: absolute;
  left: 0px;
  top: 28px;
  right: 0px;
  display: block;
  width: 32px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50px;
  background-color: #fff;
}

.bottom-bubble {
  position: absolute;
  left: 0px;
  top: 38px;
  right: 0px;
  display: block;
  width: 32px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50px;
  background-color: #fff;
}

.div-block-56 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-57 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-58 {
  position: absolute;
  left: auto;
  top: 12px;
  right: 12px;
  bottom: auto;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: #ffd900;
}

.text-block-3 {
  font-family: Inter, sans-serif;
  font-weight: 600;
}

.div-block-59 {
  width: 100%;
  height: 76px;
  max-width: none;
  background-color: #e5e5ea;
}

.heading-3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Inter, sans-serif;
  color: #000;
  font-size: 44px;
  line-height: 52px;
  font-weight: 800;
}

.body-7 {
  background-color: #0d0d0d;
}

.div-block-62 {
  width: 100%;
  max-width: 1328px;
  min-height: 320px;
  margin-top: 56px;
  padding: 32px;
  border-radius: 24px;
  background-color: #202020;
}

.div-block-65 {
  padding: 32px;
  border-radius: 16px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fff)
  );
  background-image: linear-gradient(180deg, #fff, #fff);
}

.image-12 {
  height: 100%;
}

.div-block-66 {
  height: 80%;
  max-width: 375px;
  margin-right: 0px;
  padding-right: 24px;
  background-color: #fff;
}

.div-block-67 {
  height: 80%;
  padding-right: 24px;
  background-color: #202020;
}

.div-block-68 {
  height: 80%;
  padding-right: 24px;
  background-color: #202020;
}

.div-block-69 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  margin-right: 12px;
  padding: 32px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-radius: 24px;
  background-color: #202020;
}

.image-13 {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.div-block-70 {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.div-block-71 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.div-block-72 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
}

.text-block-4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.div-block-73 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 1328px;
}

.form-block-2 {
  width: auto;
}

.alert {
  position: static;
  left: auto;
  top: 75px;
  right: 12px;
  bottom: auto;
  z-index: 9999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.18);
}

.div-block-76 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-15 {
  height: 40px;
  margin-right: 8px;
}

.image-16 {
  height: 40px;
  padding: 6px;
}

.div-block-77 {
  position: fixed;
  left: auto;
  top: 72px;
  right: 12px;
  bottom: auto;
  z-index: 9999;
}

@media screen and (min-width: 1280px) {
  .nav-link {
    padding-right: 12px;
  }

  .text-field-2 {
    font-family: Inter, sans-serif;
    line-height: 24px;
    font-weight: 400;
  }
}

@media screen and (max-width: 991px) {
  .h1---52-64.white.mob {
    font-size: 32px;
    line-height: 40px;
  }

  .h2---44-52.head.white._3 {
    width: auto;
  }

  .h2---44-52.head.white.policy {
    margin-top: 40px;
  }

  .h2---44-52.annual.prdddy {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.join {
    font-size: 32px;
    line-height: 40px;
  }

  .h2---44-52.prody {
    font-size: 24px;
    line-height: 32px;
  }

  .h5---20-32.team._2 {
    max-width: 100%;
  }

  .p2-regular-14-24.product {
    margin-bottom: 4px;
  }

  .navbar {
    padding-right: 32px;
    padding-left: 32px;
  }

  .div-block-2 {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .nav-link {
    margin-left: 0px;
  }

  .footer.open {
    padding: 56px 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer.collapsed {
    padding-right: 32px;
    padding-left: 32px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .logo-foot.small {
    margin-bottom: 16px;
  }

  .foot-link.smal {
    padding-left: 0px;
  }

  .foot-menu.small {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-5 {
    width: 100%;
    margin-bottom: 24px;
  }

  .footer-form {
    width: 100%;
  }

  .div-block-7 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-8 {
    width: auto;
    margin-bottom: 24px;
  }

  .section {
    padding: 56px 32px;
  }

  .section.fund-products {
    padding-right: 32px;
    padding-left: 32px;
  }

  .section._2.policy {
    padding-top: 96px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section.mob {
    padding-top: 96px;
  }

  .div-block-11 {
    padding: 24px 32px;
  }

  .div-block-13.new {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-15 {
    margin-right: 24px;
    margin-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-15.mobile {
    margin-right: 24px;
    margin-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-16 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .div-block-18 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form-2 {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .div-block-21 {
    overflow: scroll;
    grid-column-gap: 24px;
  }

  .howit {
    width: 300px;
    padding: 20px;
  }

  .div-block-26 {
    overflow: scroll;
    grid-column-gap: 24px;
  }

  .div-block-27 {
    width: 264px;
    padding: 20px;
  }

  .div-block-28 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .div-block-31._2 {
    width: 100%;
    margin-bottom: 16px;
  }

  .div-block-32 {
    grid-column-gap: 24px;
  }

  .div-block-33 {
    padding: 20px;
  }

  .div-block-34 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-35 {
    margin-bottom: 16px;
  }

  .div-block-38 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-39 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .div-block-40 {
    padding: 20px;
  }

  .div-block-41 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-position: 100% 0%;
    background-size: auto 100px;
  }

  .div-block-42 {
    grid-row-gap: 32px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .container {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-2 {
    height: 90vh;
  }

  .body-3 {
    background-color: #0d0d0d;
  }

  .div-block-47 {
    width: 100%;
  }

  .div-block-48._2 {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .tabs {
    margin-top: 0px;
  }

  .tabs-menu {
    margin-bottom: 16px;
  }

  .grid-2 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .div-block-50 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .html-embed {
    margin-top: 12px;
  }

  .div-block-53 {
    padding: 20px;
  }

  .faq-p.mobi {
    border-radius: 24px;
  }

  .faq {
    padding-right: 24px;
    padding-left: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  .faq-img {
    width: 50px;
    height: 50px;
  }

  .faq-body-cont {
    padding-right: 24px;
    padding-left: 24px;
  }

  .nav-menu {
    padding: 12px 24px 24px;
    background-color: #fff;
  }

  .menu-button {
    background-color: transparent;
    color: #000;
  }

  .menu-button.w--open {
    background-color: #0d0d0d;
  }

  .main-menu-button {
    background-color: #fff;
  }

  .main-menu-button.w--open {
    background-color: transparent;
  }

  .hamburger-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .hamburger-top {
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #231f20;
  }

  .hamburger-middle {
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #231f20;
  }

  .hamburger-bottom {
    width: 100%;
    height: 3px;
    border-radius: 5px;
    background-color: #231f20;
  }

  .div-block-57 {
    margin-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Inter, sans-serif;
    color: #000;
    font-size: 44px;
    line-height: 52px;
  }

  .div-block-64 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-65 {
    padding: 20px;
  }

  .div-block-66 {
    margin-bottom: 24px;
  }

  .image-14 {
    height: 32px;
    margin-bottom: 16px;
  }

  .div-block-70 {
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  .div-block-72 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .h1---52-64.yellow {
    margin-bottom: 12px;
  }

  .h1---52-64.white.mob {
    font-size: 24px;
    line-height: 32px;
    text-align: right;
  }

  .h2---44-52.head {
    margin-bottom: 32px;
  }

  .h2---44-52.join {
    font-size: 28px;
    line-height: 36px;
  }

  .h3---32-40.empty {
    width: auto;
    font-size: 24px;
    line-height: 32px;
  }

  .h5---20-32 {
    line-height: 28px;
  }

  .h5---20-32.acc {
    width: auto;
  }

  .h5---20-32.acc.empty {
    width: auto;
  }

  .button.main {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .button.outlined.foot.w--current {
    margin-right: 0px;
    margin-bottom: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .buton-text {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .div-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .main-nav {
    margin-top: 12px;
    margin-left: 0px;
  }

  .footer.collapsed {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-3 {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .section {
    padding: 40px 16px;
  }

  .section.fund-products {
    padding: 32px 16px;
  }

  .div-block-11 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .stats._1 {
    margin-bottom: 12px;
  }

  .div-block-19 {
    margin-right: 0px;
  }

  .form-2 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .submit-button {
    width: 100%;
  }

  .submit-button.disabled {
    width: 100%;
  }

  .div-block-29 {
    padding: 16px;
  }

  .image-7 {
    margin-bottom: 16px;
  }

  .div-block-30 {
    padding: 16px;
  }

  .div-block-31 {
    margin-bottom: 32px;
  }

  .div-block-32 {
    grid-column-gap: 16px;
  }

  .div-block-33 {
    padding: 16px;
  }

  .div-block-39 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .marquee-cover-horizontal {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(70%, hsla(0, 0%, 100%, 0)),
        to(#fff)
      ),
      -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(30%, hsla(0, 0%, 100%, 0)));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 70%, #fff),
      linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0) 30%);
  }

  .flex-horizontal.flip-svp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .container {
    width: 100%;
  }

  .paragraph-large {
    font-size: 16px;
  }

  .spacer-60 {
    height: 40px;
  }

  .header-secondary {
    font-size: 24px;
  }

  .col {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col.small {
    width: 100%;
  }

  .div-block-47 {
    padding: 20px;
  }

  .div-block-47.empty {
    padding: 20px;
  }

  .tabs-menu {
    margin-bottom: 24px;
  }

  .grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .div-block-50 {
    padding: 12px;
  }

  .div-block-53 {
    padding: 20px;
  }

  .faq-img {
    min-height: 40px;
    min-width: 50px;
  }

  .nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    padding-top: 12px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #fff;
  }

  .div-block-57 {
    width: 50%;
  }

  .div-block-64 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-71 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .form-block-2 {
    width: 100%;
  }

  .div-block-74 {
    width: 100%;
    margin-bottom: 12px;
  }

  .form-block-3 {
    width: 100%;
  }

  .form-block-4 {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .h1---52-64.yellow {
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 48px;
  }

  .h1---52-64.white.mob {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }

  .h2---44-52.head {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.head.white.dashh.mob {
    margin-bottom: 24px;
  }

  .h2---44-52.head.white.policy {
    margin-top: 56px;
  }

  .h2---44-52.head._2 {
    margin-bottom: 12px;
  }

  .h2---44-52.annual.prdddy {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.annual.prdddy.dashy.mob {
    font-size: 32px;
    line-height: 40px;
  }

  .h2---44-52.dolla {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.join {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 40px;
  }

  .h2---44-52.white {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.yellow {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.prody {
    font-size: 24px;
    line-height: 32px;
    text-align: right;
  }

  .h2---44-52.whte {
    font-size: 24px;
    line-height: 32px;
  }

  .h2---44-52.dash.mob {
    font-size: 32px;
    line-height: 44px;
  }

  .h3---32-40.form {
    font-size: 24px;
    line-height: 32px;
  }

  .h3---32-40.empty {
    width: auto;
    font-size: 24px;
    line-height: 32px;
  }

  .h4-bold---24-32 {
    font-size: 20px;
    line-height: 28px;
  }

  .h4-bold---24-32.prod._2 {
    width: auto;
    min-width: auto;
    font-size: 20px;
    line-height: 28px;
  }

  .h4-bold---24-32.white._2 {
    font-size: 20px;
    line-height: 28px;
  }

  .h4-bold---24-32.white._2._3 {
    margin-bottom: 16px;
  }

  .h4-bold---24-32.mob {
    font-size: 20px;
    line-height: 24px;
  }

  .h4-regular---24-32.hero {
    width: auto;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .h5---20-32 {
    line-height: 28px;
  }

  .h5---20-32.team {
    font-size: 16px;
    line-height: 24px;
  }

  .h5---20-32.team._2 {
    max-width: none;
  }

  .h5---20-32.white {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  .h5---20-32.acc {
    width: auto;
    font-size: 16px;
    line-height: 24px;
  }

  .h5---20-32.acc.empty {
    width: auto;
  }

  .p1-regular-16-24.hero {
    font-size: 14px;
    line-height: 20px;
  }

  .p1-regular-16-24.white {
    font-weight: 300;
  }

  .p1-regular-16-24.dasssh {
    margin-bottom: 4px;
  }

  .p1-regular-16-24.dasssh.mob {
    text-align: left;
  }

  .p1-regular-16-24.auth {
    overflow: visible;
    margin-top: 4px;
    font-size: 14px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .p2-regular-14-24.product {
    margin-bottom: 0px;
  }

  .p2-regular-14-24.prod {
    margin-bottom: 12px;
  }

  .button.main {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .button.outlined.foot {
    margin-right: 0px;
    margin-bottom: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .button.outlined.hero {
    margin-right: 0px;
    margin-bottom: 12px;
    padding: 16px 23px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .button.form {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 16px;
    padding-left: 16px;
  }

  .div-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-2 {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .main-nav {
    display: block;
    margin-top: 12px;
    margin-left: 0px;
  }

  .ouline-nav {
    display: block;
  }

  .nav-link {
    margin-left: 0px;
    padding-left: 0px;
  }

  .footer.open {
    padding-right: 16px;
    padding-left: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer.collapsed {
    padding-right: 16px;
    padding-left: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-3 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .logo-foot.small {
    margin-bottom: 24px;
  }

  .foot-link.smal {
    padding-left: 0px;
  }

  .foot-menu.small {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-5 {
    width: 100%;
    margin-bottom: 32px;
  }

  .footer-form {
    padding: 24px;
  }

  .form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .text-field {
    margin-bottom: 12px;
  }

  .div-block-7 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-8 {
    width: 100%;
    margin-bottom: 32px;
  }

  .body-2 {
    overflow: scroll;
  }

  .section {
    overflow: hidden;
    padding: 56px 16px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section.hero {
    position: relative;
    z-index: 1;
    overflow: visible;
    height: 80vh;
    padding: 32px 16px;
    background-image: url("../images/Слой-2.svg"), url("../images/Слой-5.svg"),
      url("../images/Слой-4.svg");
    background-position: 100% 100%, 3% 25%, 80% 15%;
    background-size: auto, auto, auto;
    background-repeat: no-repeat, no-repeat, no-repeat;
  }

  .section.fund-products {
    display: block;
    overflow: hidden;
    padding: 56px 16px;
  }

  .section.fund-products.mobile {
    display: block;
    padding-top: 56px;
  }

  .section._2.policy {
    height: auto;
    min-height: 600px;
    padding-top: 56px;
  }

  .section.mob {
    padding-top: 96px;
  }

  .div-block-9 {
    width: 100%;
  }

  .div-block-10 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-11 {
    z-index: 10;
    width: 90%;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 16px;
  }

  .stats._1 {
    margin-bottom: 32px;
  }

  .image-3 {
    height: 56px;
  }

  .div-block-12 {
    padding: 16px;
    border-radius: 16px;
  }

  .div-block-12.mobile {
    width: 300px;
  }

  .div-block-13 {
    height: auto;
    margin-bottom: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-14 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-15 {
    margin-top: 16px;
    margin-left: 0px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-15.mobile {
    margin-top: 0px;
    margin-bottom: 12px;
  }

  .image-4 {
    margin-left: 0px;
  }

  .div-block-16 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .div-block-18 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-19 {
    width: 100%;
  }

  .text-field-2 {
    font-family: Inter, sans-serif;
    line-height: 24px;
  }

  .form-2 {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .submit-button {
    width: 100%;
  }

  .submit-button.disabled {
    width: 100%;
    margin-top: 0px;
  }

  .div-block-21 {
    overflow: scroll;
    grid-column-gap: 16px;
  }

  .howit {
    width: 264px;
    padding: 24px;
  }

  .div-block-24 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .image-5 {
    height: 24px;
    margin-left: 8px;
  }

  .counter {
    font-size: 24px;
    line-height: 32px;
  }

  .div-block-26 {
    overflow: scroll;
    grid-column-gap: 16px;
  }

  .div-block-27 {
    width: 180px;
    min-height: 180px;
  }

  .image-6 {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
  }

  .div-block-28 {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .div-block-29 {
    width: 100%;
    padding: 24px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .image-7 {
    height: 80px;
    margin-bottom: 16px;
    text-align: left;
  }

  .div-block-30 {
    padding: 24px;
  }

  .div-block-31 {
    margin-right: 16px;
    margin-bottom: 32px;
    margin-left: 16px;
  }

  .div-block-31._2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 24px;
    margin-left: 0px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-32 {
    overflow: scroll;
    grid-column-gap: 16px;
  }

  .div-block-33 {
    width: 232px;
    padding: 24px;
  }

  .div-block-34 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-35 {
    min-width: auto;
    margin-right: 0px;
    margin-bottom: 24px;
  }

  .div-block-36 {
    width: 100%;
  }

  .div-block-38 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-39 {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .div-block-40 {
    padding: 24px;
  }

  .div-block-41 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-position: 100% 0%;
    background-size: 64px 56px;
  }

  .div-block-42 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block-43 {
    margin-bottom: 24px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .marquee-cover-horizontal {
    background-image: none;
  }

  .marquee-horizontal-large {
    height: 130px;
  }

  .icon-container-alt {
    width: auto;
    height: 100px;
  }

  .spacer-30._15-xsvp {
    height: 15px;
  }

  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .div-block-46 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .image-9 {
    max-width: 80%;
  }

  .grid {
    overflow: scroll;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .div-block-47 {
    width: 100%;
    margin-top: 56px;
    padding: 16px;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .div-block-47.empty {
    width: 100%;
    height: auto;
    min-height: 320px;
    margin-top: 56px;
    padding: 16px;
    border-radius: 16px;
    background-size: auto 96px;
  }

  .div-block-48 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-48._2 {
    margin-top: 56px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .tabs {
    margin-top: 32px;
  }

  .tabs-menu {
    margin-bottom: 16px;
  }

  .grid-2 {
    grid-auto-flow: row;
    grid-row-gap: 24px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .div-block-49 {
    width: 100%;
  }

  .div-block-50 {
    padding-left: 16px;
  }

  .div-block-52.tradng {
    min-height: 48px;
  }

  .div-block-53 {
    padding: 16px;
    border-radius: 16px;
  }

  .faq-p {
    display: block;
    border-radius: 16px;
  }

  .faq-p.mobile {
    display: none;
    border-radius: 16px;
  }

  .faq {
    height: 212px;
    padding: 16px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .faq-img {
    display: none;
  }

  .faq-body-cont {
    padding: 16px;
  }

  .image-10 {
    max-height: 32px;
    max-width: 80%;
  }

  .div-block-55 {
    height: 64px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .image-11 {
    height: 72px;
  }

  .nav-menu {
    padding: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
  }

  .menu-button {
    background-color: transparent;
  }

  .menu-button.w--open {
    background-color: #202020;
  }

  .div-block-57 {
    margin-bottom: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
  }

  .close {
    height: 40px;
  }

  .div-block-60 {
    width: 100%;
  }

  .div-block-61 {
    width: 100%;
  }

  .div-block-62 {
    margin-top: 32px;
  }

  .div-block-63 {
    width: 100%;
    margin-bottom: 24px;
  }

  .div-block-64 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-65 {
    padding: 16px;
  }

  .image-12 {
    max-width: 80%;
  }

  .div-block-66 {
    margin-bottom: 0px;
  }

  .div-block-69 {
    height: 100%;
    min-width: 140px;
    padding: 16px;
    border-radius: 16px;
  }

  .image-14 {
    margin-bottom: 24px;
  }

  .div-block-71 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .form-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-block-74 {
    width: 100%;
    margin-bottom: 12px;
  }

  .form-block-3 {
    width: 100%;
  }

  .form-block-4 {
    width: 100%;
  }

  .alert {
    left: auto;
    top: 72px;
    right: auto;
    bottom: auto;
    width: 100%;
    margin-left: 0px;
  }

  .div-block-77 {
    left: 12px;
  }
}

#w-node-ce67bc00-3166-4117-b502-49a1a18ed041-a290b223 {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-fd5e392b-da85-efc3-c36e-caaf48747b9d-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-fd5e392b-da85-efc3-c36e-caaf48747ba3-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-e82bc7c8-dff3-7b11-c270-6f44ce13876b-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-e82bc7c8-dff3-7b11-c270-6f44ce138771-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_60872cc5-1ab2-9636-aaa8-8103f527f9c8-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_60872cc5-1ab2-9636-aaa8-8103f527f9ce-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_24912147-7b8b-c001-de38-85b386ac79da-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_24912147-7b8b-c001-de38-85b386ac79e0-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_1370f5ab-8d61-8af1-5098-a08b5de46e33-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_1370f5ab-8d61-8af1-5098-a08b5de46e39-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-e6bf5021-fb60-096f-8e1c-359696a26acc-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-e6bf5021-fb60-096f-8e1c-359696a26ad2-a290b223 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da3a-6390b227 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da53-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da58-6390b227 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da5d-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980835-6390b227 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e7523d30-d25b-6a8d-3cb5-ef3f3298084d-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980852-6390b227 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980857-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fa75195c-e738-b60a-e130-7fe8ae62dbb4-6390b227 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fa75195c-e738-b60a-e130-7fe8ae62dbcd-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fa75195c-e738-b60a-e130-7fe8ae62dbd2-6390b227 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fa75195c-e738-b60a-e130-7fe8ae62dbd7-6390b227 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 767px) {
  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da3a-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da53-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da5d-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980835-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f3298084d-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980857-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbb4-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbcd-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbd7-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_216d5894-b874-efd5-36d6-f5273d276c24-a290b223 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da3a-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da53-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da58-6390b227 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 5;
    -ms-grid-row: 4;
    grid-row-start: 4;
  }

  #w-node-c0ed77a5-0637-6f28-33fa-bd6dfa74da5d-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980835-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f3298084d-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980852-6390b227 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-e7523d30-d25b-6a8d-3cb5-ef3f32980857-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbb4-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbcd-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbd2-6390b227 {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-fa75195c-e738-b60a-e130-7fe8ae62dbd7-6390b227 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}
